<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>群組保護</b-breadcrumb-item>
            <b-breadcrumb-item active>廣告黑名單(全店)管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">廣告黑名單(全店)管理</h4>

        <div class="row d-flex justify-content-between align-items-center">
          <div class="col-12 col-xl-4 d-flex align-items-center flex-nowrap my-3">
            <h6 class="mb-xl-0 font-weight-bold text-nowrap mr-2">群組</h6>
            <b-form-select
              v-model="currentGroupId"
              :options="
                groups.map((x) => {
                  return { text: `${x.branch_code} ${x.name}`, value: x.guard_group_id };
                })
              "
              @change="fecthGlobalBlackCustomers"
            >
              <template v-if="groups.length != 1" #first>
                <b-form-select-option value=""
                  >-- 請選擇 --</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>

          <div class="col-12 col-xl-4 d-flex flex-column flex-xl-row align-items-start align-items-xl-center my-3"></div>

          <div class="col-12 col-xl-4 d-flex flex-column flex-xl-row align-items-start align-items-xl-center my-3">
            <b-input-group class="ml-0 ml-xl-4">
              <b-form-input
                placeholder="搜尋姓名/平台ID"
                v-model="keyword"
                v-on:keyup.enter="fecthGlobalBlackCustomers"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="fecthGlobalBlackCustomers"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>

          <div class="col-12 col-xl-4 d-flex align-items-center flex-nowrap my-3" >
            <h6 class="mb-xl-0 font-weight-bold text-nowrap mr-2">設定角色</h6>
            <b-form-select
              :options="
                roleOptions.filter((x) => {
                  return x.value != 'guard';
                })
              "
              v-model="setRole"
            >
              <b-form-select-option :value="null"
                >-請選擇角色-</b-form-select-option
              >
            </b-form-select>

            <b-button
              variant="primary"
              class="ml-2 text-nowrap"
              @click="setRoleToCustomers"
              :disabled="setRole == null || selectedCustomerGroups.length == 0"
              >設定</b-button
            >
          </div>
        </div>

        <hr />

        <b-table responsive striped hover :items="globalBlackCustomers" :fields="fields">
          <template #cell(id)="data">
            <b-form-checkbox
              :value="data.item.id"
              v-model="selectedCustomerGroups"
            />
          </template>
          <template #cell(avatar_url)="data">
            <b-img-lazy
              v-bind="mainProps"
              v-show="!!data.item.avatar_url"
              :src="data.item.avatar_url"
              class="rounded-circle"
            ></b-img-lazy>
          </template>
          <template #cell(role)="data">
            <b-badge :variant="roleEnum(data.item.role).variant">{{
              roleEnum(data.item.role).text
            }}</b-badge>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import guardApi from "@/apis/guard";
import RoleChecker from "@/utils/RoleChecker";

export default {
  data() {
    return {
      roleOptions: [
        { text: "一般", value: "" },
        { text: "單店黑名單", value: "black" },
        { text: "廣告黑名單(全店)", value: "black_global" },
        { text: "管理員", value: "admin" },
        { text: "衛兵", value: "guard" },
      ],
      currentGroup: "",
      showLoading: true,
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      fields: [
        {
          key: "id",
          label: "勾選",
        },
        {
          key: 'group_name',
          label: '群組'
        },
        {
          key: "avatar_url",
          label: "頭貼",
        },
        {
          key: "name",
          label: "姓名",
        },
        {
          key: "role",
          label: "角色",
        },
        {
          key: "outer_code",
          label: "平台ID",
        },
        {
          key: "join_at",
          label: "加入日期",
          formatter: (value) => {
            return value
              ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
          sortable: true,
        },
        {
          key: "leave_at",
          label: "離開日期",
          formatter: (value) => {
            return value
              ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
          sortable: true,
        },
      ],
      currentGroupId: null,
      groups: [],
      globalBlackCustomers: [],
      selectedCustomerGroups: [],
      setRole: null,
      keyword: null,
    };
  },
  mounted() {
    this.fetchGuardGroups();
    this.fecthGlobalBlackCustomers();
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  methods: {
    async fetchGuardGroups() {
      this.showLoading = true;

      try {
        const { data } = await guardApi.getActiveGuardGroups();
        this.groups = data.data;
      } catch (error) {
        console.error(error);
        alert("取得群組資料錯誤");
      }
      this.showLoading = false;
    },
    async fecthGlobalBlackCustomers() {
      this.showLoading = true;

      try {
        const { data } = await guardApi.getGlobalBlackCustomersOfGuardGroup({
          keyword: this.keyword,
          group_id: this.currentGroupId
        });

        this.globalBlackCustomers = data.data;
      } catch (error) {
        console.error(error);
        alert("取得廣告黑名單（全店）錯誤");
      }
      this.showLoading = false;
    },
    async setRoleToCustomers() {
      try {
        let response = await guardApi.setRoleToGlobalBlackCustomers(this.currentGroup, {
          customer_group_ids: this.selectedCustomerGroups,
          role: this.setRole == "" ? null : this.setRole,
        });

        if (
          response.status &&
          response.status === 200 &&
          response.data.success
        ) {
          this.$swal.fire({
            title: "更新成功",
            type: "success",
          });

          this.fecthGlobalBlackCustomers();
        } else {
          alert("角色設定錯誤");
        }
      } catch (error) {
        alert("角色設定錯誤");
      }
    },

    roleEnum(role) {
      if (role === "admin") {
        return {
          text: "管理員",
          variant: "danger",
        };
      } else if (role === "guard") {
        return {
          text: "衛兵",
          variant: "success",
        };
      } else if (role === "black") {
        return {
          text: "單店黑名單",
          variant: "dark",
        };
      } else if (role === "black_global") {
        return {
          text: "廣告黑名單(全店)",
          variant: "dark",
        };
      } else {
        return {
          text: "一般",
          variant: "secondary",
        };
      }
    },
    checkRole(roles) {
      const checker = new RoleChecker();
      return checker.check(roles);
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
